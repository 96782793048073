//==============================================================================
// Copyright
//
// Allows the automation of copyright year
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';

import { ICopyrightProps } from './copyright.props.autogenerated';

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'copyright';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * Copyright component
 * @extends {React.PureComponent<ICopyrightProps<{}>>}
 */
//==============================================================================
class Copyright extends React.PureComponent<ICopyrightProps<{}>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { className } = this.props.config;

        return (
            <div className={classnames(BASE_CLASS, className)}>
                {this._copyrightTextFormatted()}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _copyrightTextFormatted(): JSX.Element {
        const { copyrightText } = this.props.config;
        const copyrightYear = new Date().getFullYear().toString();

        return (
            <React.Fragment>
                <span className={cls('symbol')}>©</span>
                <span className={cls('prefix')}>{this.props.resources.copyright_prefix}</span>
                <span className={cls('year')}>{copyrightYear}</span>
                {copyrightText && <span className={cls('text')}>{copyrightText}</span>}
            </React.Fragment>
        );
    }
}

export default Copyright;
